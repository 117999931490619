<template>
  <div style="display: flex; justify-content: start; align-items: center">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-icon small class="mr-2 iconeEditar" @click="openDialogEdit">
            mdi-pencil
          </v-icon>
        </span>
      </template>
      <span>Editar</span>
    </v-tooltip>

    <v-tooltip bottom :color="switchColor">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">
          <v-switch
            v-model="switchRegister"
            color="success"
            value
            @click="swithRegister()"
          ></v-switch>
        </span>
      </template>
      <span>{{ switchStatus }}</span>
    </v-tooltip>

    <v-dialog
      v-model="dialogEditar"
      width="600px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-edit">
          <h4>Editar Informações</h4>
        </div>

        <AlertError v-model="displayError" :resource="displayError" />

        <div class="space"></div>

        <v-form
          @submit.prevent="edit"
          v-model="valid"
          lazy-validation
          ref="form"
        >
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                label="Nome do Orgão Entidade"
                placeholder="Orgão Entidade"
                outlined
                v-model="resource.name"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                label="Responsável"
                placeholder="Responsável"
                outlined
                v-model="resource.responsible"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                class="rounded-lg"
                label="Email"
                placeholder="Email"
                outlined
                v-model="resource.email"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                class="rounded-lg"
                label="CNPJ"
                placeholder="CNPJ"
                outlined
                v-model="resource.cnpj"
                v-mask="'##.###.###/####-##'"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                class="rounded-lg"
                label="Telefone"
                placeholder="Telefone"
                v-mask="'(##) #####-####'"
                outlined
                v-model="resource.phone"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                label="Logradouro"
                placeholder="Logradouro"
                outlined
                v-model="address.public_place"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                class="rounded-lg"
                label="Bairro"
                placeholder="Bairro"
                outlined
                v-model="address.district"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                class="rounded-lg"
                label="CEP"
                placeholder="CEP"
                v-mask="'#####-###'"
                outlined
                v-model="address.cep"
                :rules="nameRules"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                type="number"
                min="0"
                class="rounded-lg"
                label="Número"
                placeholder="Número"
                outlined
                v-model="address.address_number"
                :rules="numberResidencie"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                dense
                outlined
                v-model="address.country_id"
                :items="countryList"
                :rules="nameRules"
                item-text="name"
                item-value="id"
                label="País"
                persistent-hint
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                persistent-hint
                dense
                outlined
                v-model="address.state_id"
                :items="stateList"
                :rules="nameRules"
                item-text="name"
                item-value="id"
                label="Estado"
              ></v-select>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                dense
                outlined
                persistent-hint
                v-model="address.county_id"
                :items="countyList"
                :rules="nameRules"
                item-text="name"
                item-value="id"
                label="Cidade"
              ></v-select>
            </v-col>
          </v-row>

          <v-card-actions class="cardFooter">
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg btn"
              color="red"
              text
              elevation="0"
              @click="cancelEdit"
            >
              <span>Cancelar</span>
            </v-btn>

            <v-btn
              class="rounded-lg"
              color="success"
              elevation="0"
              type="submit"
            >
              <span class="textBtn">Enviar dados</span>
              <v-icon small class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAPagar"
      width="400px"
      class="modelDialog"
      persistent
    >
      <v-card class="card rounded-lg">
        <div class="banner-delete">
          <h4>Deletar Orgão/Entidade</h4>
        </div>

        <div class="textInfo">
          <h6 class="textDelete">
            Deseja deletar <span class="userName">{{ resource.name }}</span> dos
            orgão/entidades cadastrados?
          </h6>
          <h6 class="alertText">(Essa ação não pode ser revertida)</h6>
        </div>

        <v-card-actions class="cardFooter">
          <v-btn
            class="rounded-lg btn"
            color="red"
            text
            elevation="0"
            @click="dialogAPagar = false"
          >
            <span>Cancelar</span>
          </v-btn>

          <v-btn
            class="rounded-lg"
            color="#27ae60"
            elevation="0"
            type="submit"
            @click="deletar"
          >
            <span class="textBtn">Confirmar</span>
            <v-icon color="#FFFFFF" small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AlertSuccess v-model="displaySuccess" :resource="displaySuccess" />
  </div>
</template>

<script>
import axios from "@/axios";
import AlertSuccess from "../alerts/Success-component.vue";
import AlertError from "../alerts/Error-component.vue";

export default {
  data() {
    return {
      valid: true,
      nameRules: [(v) => !!v || "O campo é obrigatório"],
      numberResidencie: [
        (v) => !!v || "Campo obrigatório!",
        (v) => (v && v.length <= 4) || "Você excedeu o tamanho máximo!",
        (v) => (v && v > 0) || "O número é abaixo do esperado",
      ],

      name: "",
      responsible: "",
      email: "",
      cnpj: "",
      phone: "",
      address: {},
      agencyList: [],
      countryList: [],
      stateList: [],
      countyList: [],

      dialogEditar: false,
      dialogAPagar: false,
      switchRegister: false,
      switchStatus: "",
      switchColor: "",

      displayError: {
        display: false,
        message: [],
      },

      displaySuccess: {
        display: false,
        message: [],
      },
    };
  },

  props: {
    resource: {
      type: Object,
    },
  },

  components: {
    AlertSuccess,
    AlertError,
  },

  created() {
    this.verifyStatusRegister();
  },

  methods: {
    openDialogEdit() {
      axios.get(`/county`).then((response) => {
        this.countyList = response.data;
      });
      axios.get(`/country`).then((response) => {
        this.countryList = response.data;
      });
      axios.get(`/state`).then((response) => {
        this.stateList = response.data;
      });

      this.address.country_id = Number(this.resource.address[0].country_id)
      this.address.cep = this.resource.address[0].cep;
      this.address.public_place = this.resource.address[0].public_place;
      this.address.address_number = this.resource.address[0].address_number;
      this.address.district = this.resource.address[0].district;   
      this.address.state_id = this.resource.address[0].state_id;   
      this.address.state_id = Number(this.resource.address[0].state_id)
      this.address.county_id = Number(this.resource.address[0].county_id)
      
      
      this.dialogEditar = true;
    },

    verifyStatusRegister() {
      if (this.resource.deleted_at == null) {
        this.switchRegister = true;
        this.switchStatus = "Desativar";
        this.switchColor = "red";
        return;
      }
      this.switchRegister = false;
      this.switchStatus = "Ativar";
      this.switchColor = "success";
    },
    swithRegister() {
      if (!this.switchRegister) {
        this.disableRegister();
        this.$emit("eventname", "");
        return;
      }

      this.enableRegister();
      this.$emit("eventname", "");
    },
    enableRegister() {
      axios.put(`/agency/restore/${this.resource.id}`).then((response) => {
        this.displaySuccess.message = response.data.message;
        this.displaySuccess.display = true;
      });
    },

    disableRegister() {
      axios.delete(`/agency/${this.resource.id}`).then((response) => {
        this.displaySuccess.message = response.data.message;
        this.displaySuccess.display = true;
      });
    },

    edit() {
      if (this.$refs.form.validate() == true) {
        const dados = new Object();
        dados.id = this.resource.id;
        dados.name = this.resource.name;
        dados.responsible = this.resource.responsible;
        dados.cnpj = this.resource.cnpj;
        dados.phone = this.resource.phone;
        dados.email = this.resource.email;
        dados.address = {
          cep: this.address.cep,
          public_place: this.address.public_place,
          address_number: this.address.address_number,
          district: this.address.district,
          country_id: this.address.country_id,
          state_id: this.address.state_id,
          county_id: this.address.county_id,
        };
        axios
          .put(`/agency/${dados.id}`, dados)
          .then((response) => {
            this.$emit("eventname", "");
            this.displaySuccess.message = response.data.message;
            this.displaySuccess.display = true;
            this.dialogEditar = false;
          })
          .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
              error = value;
            });

            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
              this.displayError.display = false;
            }, 3000);
          });
      }
    },

    cancelEdit() {
      this.dialogEditar = false;
      this.$emit("eventname", "");
    },

    deletar() {
      this.dialogAPagar = false;
      this.$emit("eventname", this.resource.id);
    },
  },
};
</script>

<style scoped>
.space {
  height: 10px;
}

.iconeEditar:hover {
  color: #54c6eb;
}

.iconeDeletar:hover {
  color: #ad2831;
}

.card {
  padding: 20px;
}

.banner-edit {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgba(95, 195, 228, 1) 0%,
    rgba(93, 148, 229, 1) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-delete {
  background: #e55d87;
  background: linear-gradient(
    160deg,
    rgb(235, 30, 57) 0%,
    rgb(248, 96, 36) 100%
  );
  border-radius: 5px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner-edit > h4,
.banner-delete > h4 {
  color: #ffffff;
  padding: 10px;
}

.textInfo {
  padding: 15px;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 8px;
  text-align: center;
}

.textDelete {
  font-family: "Poppins";
  font-weight: 400;

  font-size: 14px;
}

.userName {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.alertText {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 12px;

  margin-top: 15px;

  color: #f44336;
}

.cardFooter {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}

.cardFooter {
  padding: 0 !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}
</style>
